import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { CSSObject, Theme, useTheme } from '@mui/material/styles';
import { NavLink, useLocation } from 'react-router-dom';

import useAccessControl from 'features/auth/access-control/useAccessControl';
import { useTranslation } from 'react-i18next';
import { SideBarNavigationItem } from './sideBarDefinition';

const drawerWidth = 275;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 10px)`,
  [theme.breakpoints.up('sm')]: { width: `calc(${theme.spacing(6)} + 10px)` }
});

interface IProps {
  open: boolean;
}

export const AdminSideBar = ({ open }: IProps) => {
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();
  const { getVisibleNavigationItems } = useAccessControl();
  const isActive = (route: SideBarNavigationItem) => {
    return location.pathname.includes(route.path);
  };

  const visibleNavigationItems = getVisibleNavigationItems();

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme)
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme)
        })
      }}
    >
      <Toolbar />

      <List>
        {visibleNavigationItems
          //.filter((nav) => !nav.hidden)
          .map((sideBarNavigationItem: SideBarNavigationItem) => {
            const Icon = sideBarNavigationItem.iconComponent;

            return (
              <NavLink
                to={sideBarNavigationItem.path}
                key={sideBarNavigationItem.key}
                style={{ textDecoration: 'none' }}
              >
                <ListItem
                  sx={{
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                    width: '90%',
                    height: 48,
                    mx: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& svg': {
                      color: theme.palette.primary.main
                    },
                    gap: 1,
                    ...(isActive(sideBarNavigationItem) && {
                      color: theme.palette.common.white,
                      backgroundColor: '#64B6E4',
                      borderRadius: '5px',
                      '& svg': {
                        color: theme.palette.common.white
                      },
                      '&:hover': {
                        backgroundColor: '#64B6E4'
                      }
                    })
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 'fit-content'
                    }}
                  >
                    {Icon && <Icon />}
                  </ListItemIcon>
                  {open && <ListItemText>{t(`${sideBarNavigationItem.title}`)}</ListItemText>}
                </ListItem>
              </NavLink>
            );
          })}
      </List>
    </Drawer>
  );
};

export default AdminSideBar;
