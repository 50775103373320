import { appInsights } from 'app/AppInsights';
import { useAppSelector } from 'app/store';
import { useCallback, useEffect } from 'react';

function useTrackingPage(name: string, canTracking: boolean | undefined = true, pageUrl = window.location.href) {
  const { info } = useAppSelector((state) => state.student);

  const trackDurationPageView = useCallback(
    (mountTime: number) => {
      const unmountTime = new Date().getTime();
      const sessionKey = sessionStorage.getItem('sessionKey');
      appInsights.trackEvent(
        { name },
        info
          ? {
              textBookId: `${info?.textBookId}`,
              classId: `${info?.classId}`,
              className: `${info.className}`,
              textBookName: `${info.textBookName}`,
              sessionKey,
              clientIp: sessionStorage.getItem('ipAddress'),
              duration: unmountTime - mountTime,
              pageUrl
            }
          : undefined
      );
    },
    [info, name, pageUrl]
  );

  useEffect(() => {
    const mountTime = new Date().getTime();

    window.addEventListener('beforeunload', () => {
      //Handle close tab
      if (canTracking) {
        trackDurationPageView(mountTime);
      }
    });

    return () => {
      //Hanlde redirect to another pages
      if (canTracking) {
        trackDurationPageView(mountTime);
      }
    };
  }, [trackDurationPageView, canTracking]);
}

export default useTrackingPage;
