import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
const StudentAppSound = () => {
  const location = useLocation();
  const [triggerProps, setTriggerProps] = useState<number | null>(null);
  const isPlaying = useRef(false);
  const audio = useMemo(() => new Audio('/sounds/sound.mp3'), []);

  const regrexContentPage = /^\/student\/content\/.*/;
  const canListenMusic =
    (location.pathname.includes('/student') &&
      !regrexContentPage.test(location.pathname) &&
      location.pathname !== '/student/community/preview') ||
    triggerProps === null;

  const StopMusic = useCallback(() => {
    audio.loop = false;
    audio.pause();
    audio.currentTime = 0;
    isPlaying.current = false;
  }, [audio]);

  const PlayMusic = useCallback(() => {
    audio.loop = true;
    audio.play().catch(() => {
      StopMusic();
    });
    isPlaying.current = true;
  }, [audio, StopMusic]);

  const handleVisibilityChange = useCallback(() => {
    const isHidden = document['hidden'];
    if (isHidden) {
      StopMusic();
    }
  }, [StopMusic]);

  useEffect(() => {
    window.addEventListener('click', () => {
      setTriggerProps(new Date().getTime());
    });
    return () => {
      window.removeEventListener('click', () => {
        setTriggerProps(null);
      });
    };
  }, []);

  useEffect(() => {
    if (!canListenMusic) {
      return StopMusic();
    }
  }, [triggerProps, PlayMusic, StopMusic, location, canListenMusic]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return <></>;
};

export default StudentAppSound;
