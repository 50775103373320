import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { appConfigs } from 'common/consts/configs';
import { createBrowserHistory } from 'history';
import { PropsWithChildren } from 'react';
const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: appConfigs.appInsightsConnectionString,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});

appInsights.loadAppInsights();

export { appInsights };

const AppInsight = ({ children }: PropsWithChildren) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary onError={() => <p>Something went wrong...</p>} appInsights={reactPlugin}>
        <>{children}</>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};

export default AppInsight;
