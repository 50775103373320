import { Box, createTheme, ThemeProvider } from '@mui/material';
import { studentClient } from 'apis';
import { ContentType } from 'apis/nswag';
import { useAppContext } from 'app/AppContext';
import { STUDENT_APP_ROUTES } from 'app/AppRoutes';
import { useAppSelector } from 'app/store';
import axios, { CancelToken } from 'axios';
import GlobalDialog from 'components/Dialogs/GlobalDialog';
import GlobalAlert from 'components/GlobalAlert';
import { useCallback, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';
import appThemes from 'styles';
import themeTypography from 'styles/typography';
import { v4 as uuidv4 } from 'uuid';

const StudentLayout = () => {
  const {
    state: {
      language: { lang }
    }
  } = useAppContext();
  const { contentHashCode, classHashCode } = useParams();

  const navigate = useNavigate();
  const { info, previousInfo, hasProfiles } = useAppSelector((state) => state.student);
  const rootThemes = appThemes();
  const studentThemes = createTheme({
    ...rootThemes,
    typography: { ...themeTypography(), fontFamily: lang === 'jp' ? 'ZenKaKu' : 'DotYouris' },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 1920
      }
    }
  });
  const getContentDetail = useCallback(
    async (cancelToken?: CancelToken) => {
      const contentDetail = (await studentClient.getContentDetailByHashCode(contentHashCode || '', cancelToken)) || '';
      return contentDetail;
    },
    [contentHashCode]
  );
  const getPublicIpV4 = async () => {
    const { data }: { data: { ip: string } } = await axios.get('https://api.ipify.org?format=json');
    sessionStorage.setItem('ipAddress', data.ip);
  };
  const PathsNotRedirectProfile = useMemo(
    () => [
      '/student/profiles',
      '/student/class-completed',
      '/student/community/list',
      '/student/community/preview',
      '/student/game-list'
    ],
    []
  );

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function redirectPage(cancelToken?: CancelToken) {
      let type = '';
      let redirectUrl = `${STUDENT_APP_ROUTES.StudentProfiles.path}`;
      let hashCode = '';
      let isRecordType = false;

      const contentDetail = contentHashCode ? await getContentDetail(cancelToken) : {};

      if (contentHashCode) {
        hashCode = contentDetail.classHashCode ?? '';
        isRecordType = contentDetail.type === ContentType.Record;
        type = 'content';
      }

      if (type === 'content') {
        if (!isRecordType || !hasProfiles) {
          return;
        }
      }

      if (classHashCode) {
        hashCode = classHashCode;
        type = 'class';
      }

      if (hashCode) {
        redirectUrl += `?class=${hashCode}`;
      }
      return navigate(redirectUrl, {
        state: {
          type,
          redirectTo: window.location.pathname,
          isRecordType,
          classHashCode: hashCode
        }
      });
    }
    if (!info && !PathsNotRedirectProfile.includes(window.location.pathname)) {
      redirectPage(cancelToken.token);
    }
    return () => {
      cancelToken.cancel();
    };
  }, [info, hasProfiles, navigate, getContentDetail, contentHashCode, classHashCode, PathsNotRedirectProfile]);

  useEffect(() => {
    getPublicIpV4();
  }, []);

  useEffect(() => {
    if (info) {
      if (previousInfo ? info.id !== previousInfo.id : true) {
        const sessionKey = uuidv4();
        sessionStorage.setItem('sessionKey', sessionKey);
      }
    }
  }, [info, previousInfo]);

  return (
    <>
      <ThemeProvider theme={studentThemes}>
        <GlobalDialog />
        <GlobalAlert />
        <Box sx={{ display: 'flex' }}>
          <Box component="main" sx={{ flexGrow: 1, minHeight: '100vh' }}>
            <Outlet />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default StudentLayout;
