import { Box, ThemeProvider, createTheme } from '@mui/material';
import { authClient } from 'apis';
import { RefreshTokenCommand } from 'apis/nswag';
import { useAppContext } from 'app/AppContext';
import { ANONYMOUS_ROUTES } from 'app/AppRoutes';
import AdminHeader from 'app/components/header/AdminHeader';
import AdminSidebar from 'app/components/sidebar/AdminSidebar';
import { beforeTokenExpiryTimeInSecond } from 'common/consts/configs';
import useCurrentAdminAppRoute from 'common/hooks/useCurrentAdminAppRoute';
import useTrackingPage from 'common/hooks/useTrackingPage';
import GlobalDialog from 'components/Dialogs/GlobalDialog';
import GlobalAlert from 'components/GlobalAlert';
import { getUnixTime } from 'date-fns';
import { useAccessControl } from 'features/auth/access-control';
import { AuthActionTypes } from 'features/auth/login-reducer';
import { getSavedAuthInfoFromLocalStorage, getTokenExp, isTokenExpired } from 'features/auth/utils';
import { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router';
import { Navigate, useNavigate } from 'react-router-dom';
import { appThemes } from 'styles/theme';
import themeTypography from 'styles/typography';

const AdminLayout = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const currentRoute = useCurrentAdminAppRoute();
  const { getVisibleNavigationItems } = useAccessControl();

  const {
    dispatch,
    state: {
      auth: { info: authInfo },
      language: { lang }
    }
  } = useAppContext();
  const tokenExp = getTokenExp(authInfo?.accessToken);
  const remainSecondsBeforeExpire = useMemo(
    () => (tokenExp ? tokenExp - beforeTokenExpiryTimeInSecond - getUnixTime(new Date()) : 0),
    [tokenExp]
  );
  const currentURL = window.location.href;
  const baseURL = window.location.protocol + '//' + window.location.host + '/';

  useTrackingPage('PAGE_BASE_URL', currentURL === baseURL);

  useEffect(() => {
    if (currentURL === baseURL) {
      return navigate('/student/profiles', { replace: true });
    }

    const refreshToken = async (request: RefreshTokenCommand) => {
      try {
        const refreshTokenResult = await authClient.refreshToken(request);
        dispatch({ type: AuthActionTypes.AUTH_INFO_SET, payload: refreshTokenResult });
      } catch {
        dispatch({ type: AuthActionTypes.AUTH_LOG_OUT });
        navigate(`/${ANONYMOUS_ROUTES.Login.path}`);
      }
    };

    if (!!authInfo?.refreshToken && (tokenExp || remainSecondsBeforeExpire < 0)) {
      const timer = setTimeout(() => {
        const savedAuthInfo = getSavedAuthInfoFromLocalStorage();
        refreshToken({
          accessToken: savedAuthInfo?.accessToken,
          refreshToken: savedAuthInfo?.refreshToken
        });
      }, remainSecondsBeforeExpire * 1000);

      return () => {
        clearTimeout(timer);
      };
    }

    const isLoggedIn = !!authInfo && authInfo?.accessToken && !isTokenExpired(authInfo?.accessToken);

    if (!isLoggedIn) {
      navigate(`/${ANONYMOUS_ROUTES.Login.path}`);
    }
  }, [authInfo, navigate, dispatch, open, remainSecondsBeforeExpire, tokenExp, currentURL, baseURL]);
  const rootThemes = appThemes();
  const adminThemes = createTheme({
    ...rootThemes,
    typography: { ...themeTypography(), fontFamily: lang === 'jp' ? 'ZenKaKu' : 'Roboto' }
  });

  if (
    currentRoute !== null &&
    currentRoute.allowedRoles &&
    !currentRoute.allowedRoles.includes(authInfo?.role || '') &&
    currentURL !== baseURL
  ) {
    const visibleNavigationItems = getVisibleNavigationItems();
    const defaultPage = visibleNavigationItems[0]?.path || `/${ANONYMOUS_ROUTES.Login.path}`;

    return <Navigate to={defaultPage} replace />;
  }

  return (
    <ThemeProvider theme={adminThemes}>
      <GlobalDialog />
      <GlobalAlert />
      <Box sx={{ display: 'flex' }}>
        <AdminHeader setOpen={setOpen} open={open} />
        <AdminSidebar open={open} />
        <Box component="main" sx={{ flexGrow: 1, mt: 8 }}>
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AdminLayout;
