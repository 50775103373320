import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageStudentProfiles } from './types';

// Define a type for the slice state
interface IStudentState {
  info: LocalStorageStudentProfiles | null;
  previousInfo: LocalStorageStudentProfiles | null;
  hasProfiles: boolean;
}
const profilesKey = localStorage.getItem('profiles');
const profiles = profilesKey ? JSON.parse(profilesKey) : [];
const initialState: IStudentState = {
  info: null,
  previousInfo: null,
  hasProfiles: profiles.length !== 0
};

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LocalStorageStudentProfiles | null>) => {
      if (action.payload === null && state.info) {
        state.previousInfo = state.info;
      }
      state.info = action.payload;
    },
    updateHasProfiles: (state, action: PayloadAction<boolean>) => {
      state.hasProfiles = action.payload;
    }
  }
});

export const { login, updateHasProfiles } = studentSlice.actions;

export default studentSlice.reducer;
