import { LoginResponse } from 'apis/nswag';
import { useAppContext } from 'app/AppContext';
import { SideBarNavigationItem, sideBarNavigationItems } from 'app/components/sidebar/sideBarDefinition';
import { Roles } from 'common/consts/roles';
import { isTokenExpired } from 'features/auth/utils';
import produce from 'immer';

export interface IUseAccessControl {
  isValidRole: (allowedRoles: string[], role?: string) => boolean;
  isAuthenticated: boolean;
  user: LoginResponse | null | undefined;
  isPermitted: boolean;
  getVisibleNavigationItems: (role?: string) => SideBarNavigationItem[];
}

export interface IUseAccessControlOptions {
  defaultAllowedRoles?: string[];
}

export const useAccessControl = ({ defaultAllowedRoles = [] }: IUseAccessControlOptions = {}): IUseAccessControl => {
  const {
    state: {
      auth: { info: authInfo }
    }
  } = useAppContext();

  const isAuthenticated = !!authInfo?.accessToken && !isTokenExpired(authInfo?.accessToken);

  const isValidRole = (allowedRoles: string[], role?: string) => {
    if (allowedRoles.length === 0) {
      return true;
    }

    const userRole = role ?? authInfo?.role ?? '';

    return allowedRoles.includes(userRole) || userRole === Roles.SuperAdmin;
  };

  const getVisibleNavigationItems = (role?: string) => {
    return produce(sideBarNavigationItems, (draftGroups) =>
      draftGroups.filter((item: any) => item.allowedRoles && isValidRole(item.allowedRoles, role))
    );
  };

  return {
    isValidRole,
    isAuthenticated,
    user: authInfo,
    isPermitted: isValidRole(defaultAllowedRoles),
    getVisibleNavigationItems
  };
};

export default useAccessControl;
