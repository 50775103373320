export const appConfigs = {
  apiUrl: process.env.REACT_APP_API_URL,
  appInsightsConnectionString: process.env.REACT_APP_INSIGHT_CONNECTION_STRING,
  appId:process.env.REACT_APP_APPLICATION_ID,
  xApiKey: process.env.REACT_APP_X_API_KEY
};

export const defaultPageSizeOptions = [10, 20, 50, 100];

export const defaultDebounceTime = 500;

export const beforeTokenExpiryTimeInSecond = 180;

export const defaultTrackEventCondition = !window.location.href.includes('profiles');
