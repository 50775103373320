import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router';
import { usePrevious } from 'react-use';

const ScrollToTop = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const previousLocation = usePrevious(location);
  useEffect(() => {
    if (location.pathname !== previousLocation?.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location, previousLocation]);

  return <>{children}</>;
};

export default ScrollToTop;
