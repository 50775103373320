import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { ADMIN_APP_ROUTES } from 'app/AppRoutes';
import { Roles } from 'common/consts/roles';

export interface SideBarNavigationItem {
  key: string;
  path: string;
  title?: string | null;
  iconComponent?: React.FC;
  allowedRoles: string[];
}

export const sideBarNavigationItems: SideBarNavigationItem[] = [
  {
    key: 'user',
    path: `/${ADMIN_APP_ROUTES.UserList.path}`,
    title: 'navigation.UserManagement',
    iconComponent: GroupOutlinedIcon,
    allowedRoles: [Roles.Administrator, Roles.SchoolOwner]
  },
  {
    key: 'class',
    path: `/${ADMIN_APP_ROUTES.ClassManagement.path}`,
    title: 'navigation.ClassManagement',
    iconComponent: GridViewOutlinedIcon,
    allowedRoles: [Roles.Administrator, Roles.Teacher, Roles.SchoolOwner]
  },
  {
    key: 'content',
    path: `/${ADMIN_APP_ROUTES.ContentManagement.path}`,
    title: 'navigation.TextBookManagement',
    iconComponent: ListAltOutlinedIcon,
    allowedRoles: [Roles.Administrator]
  },
  {
    key: 'report',
    path: `/${ADMIN_APP_ROUTES.ReportManagement.path}`,
    title: 'navigation.ReportManagement',
    iconComponent: Inventory2OutlinedIcon,
    allowedRoles: [Roles.Administrator]
  }
];
